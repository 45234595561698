<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-3">
      <v-flex xs12 sm6 md3 lg3 class="mt-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          class="mr-3"
          @click="goBack()"
        >
          <v-icon small left>fas fa-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-flex xs12 sm6 md9 lg9 class="text-right mt-1">
        <v-btn
          small
          outlined
          class="mr-3"
          @click="editProduct($route.params.id)"
          :color="$store.state.primaryColor"
        >
          <v-icon left small>edit</v-icon>
          <span class="caption">{{ $t("edit details") }}</span>
        </v-btn>
        <v-btn
          small
          outlined
          class="mr-3 mt-1"
          @click="delDialog = true"
          :color="$store.state.secondaryColor"
        >
          <v-icon left small>delete</v-icon>
          <span class="caption">{{ $t("delete") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <!-- <v-icon>delete</v-icon> -->
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to delete this product") }}
            ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            small
            @click="deleteProduct"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("deleted") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackbar = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not delete product. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <v-layout row wrap class="mt-1">
        <v-flex xs12 sm6 md6 lg6>
          <span class="body-1 font-weight-black text-capitalize" id="print">
            <barcode
              v-bind:value="product.barcode"
              format="CODE39"
              height="19"
              width="1.5"
              fontSize="12"
              font="Courier"
              options="{
                    'Short barcode'
                    }"
            >
              Show this if the rendering fails.
            </barcode>
          </span>
        </v-flex>

        <v-spacer></v-spacer>
        <v-flex xs12 sm6 md6 lg6 class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                outlined
                :color="$store.state.secondaryColor"
                v-bind="attrs"
                v-on="on"
                @click="printPage()"
              >
                <v-icon small left :color="$store.state.secondaryColor"
                  >mdi-printer</v-icon
                >{{ $t("print barcode") }}
              </v-btn>
            </template>
            <span>{{ $t("print barcode") }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-space-between>
        <v-flex xs12 sm12 md3 lg3>
          <v-card class="mx-auto" max-width="344">
            <v-card-title class="body-1">{{
              $t("product image")
            }}</v-card-title>
            <v-divider></v-divider>
            <v-img
              :src="product.avatar"
              height="200px"
              @click="dialog = true"
            ></v-img>
          </v-card>

          <!-- DISPLAY PRODUCT IMAGE IN LARGE -->

          <v-dialog v-model="dialog" width="550">
            <v-card dark>
              <v-img :src="product.avatar" contain></v-img>
            </v-card>
          </v-dialog>
        </v-flex>

        <v-flex xs12 sm12 md9 lg9>
          <v-list dense class="elevation-1">
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("product name") }}:
                </span>
                <span class="">{{ product.name }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("product barcode") }} # :
                </span>
                <span class="">{{ product.barcode }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="body-1 font-weight-black text-capitalize"
                  >{{ $t("product category") }}:
                </span>

                <span class="">{{ getCategoryName(product.categoryID) }} </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("about") }}:
                </span>
                <span class="">{{ product.description.about }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("date ordered") }}:
                </span>
                <span class="">{{ product.date.ordered }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("date delivered") }}:
                </span>
                <span class="">{{ product.date.delivered }} </span>
              </v-col>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("expiry date") }}:
                </span>
                <span class="">{{ product.expiryDate }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("product origin") }}:
                </span>
                <span class="">{{ product.origin }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("remaining stock") }}:
                </span>
                <span class="">{{ product.quantity.inStock }} </span>
              </v-col>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("model") }}:
                </span>
                <span class="">{{ product.description.model }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black">{{ $t("brand") }}: </span>
                <span class="">{{ product.description.brand }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("type") }}:
                </span>
                <span class="">{{ product.description.type }} </span>
              </v-col>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("size") }}:
                </span>
                <span class="">{{ product.description.size }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("color") }}:
                </span>
                <span class="">{{ product.description.color }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("buying price") }}:
                </span>
                <span class=""
                  >{{ currencyFormat(product.price.buying) }}
                </span>
              </v-col>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="body-1 d-flex flex-wrap">
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("selling price") }}:
                </span>
                <span class=""
                  >{{ currencyFormat(product.price.selling) }}
                </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("price limit") }}:
                </span>
                <span class="">{{ currencyFormat(product.price.limit) }} </span>
              </v-col>
              <v-col cols="12" md="4">
                <span class="font-weight-black text-capitalize"
                  >{{ $t("discount") }}:
                </span>
                <span class=""
                  >{{ currencyFormat(product.price.discount) }}
                </span>
              </v-col>
            </v-list-item>
          </v-list>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import { printer } from "@/plugins/functions";
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";

export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    dialog: false,
    pleaseWait: true,
    product: "",
    e1: 1,
    snackbar: false,
    snackError: false,
    timeout: 2000,
    delDialog: false,
    loading: false,
    categories: [],
  }),

  created() {
    this.getCategories();
    this.getProduct();
  },

  methods: {
    printer,
    getProduct() {
      this.pleaseWait = true;
      db.collection("products")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.product = doc.data();

          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);

          this.pleaseWait = false;
        });
    },

    getCategories() {
      db.collection("productCategory")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categories.push({
              id: doc.id,
              category: doc.data().category,
            });
          });
          //  console.log(JSON.stringify(this.categories))
        });
    },

    getCategoryName(id) {
      let catId = this.categories.find((item) => item.id == id);
      let category = catId.category;
      return category;
    },

    editProduct(prodID) {
      this.$router.push({ name: "EditProduct", params: { id: prodID } });
    },

    deleteProduct() {
      db.collection("products")
        .doc(this.$route.params.id)
        .delete()
        .then(() => {
          this.snackbar = true;
          setTimeout(function () {
            window.history.back();
          }, 2000);
        })
        .catch(() => {
          this.snackError = true;
        });
    },
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },

    printPage() {
      this.printer("print", "Receipt");
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
